// @mui components
import { Box, Typography, useTheme } from "@mui/material";

function Footer() {
  const theme = useTheme();

  const { typography } = theme;

  const { size } = typography;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      paddingX={1.5}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography color="text.main" fontSize={size.sm}>
          &copy; LIFEPET PLANO DE SAÚDE LTDA - 48.879.524/0001-20 - Rua Ceará, Nº
          1513, Andar 1 Andar Conj 101 SL1, Praia da Costa, Vila Velha/ES, CEP: 29.101-290 -{" "}
          {new Date().getFullYear()}, Todos os direitos reservados
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
